<style>
table.dataTable>tbody>tr.child ul.dtr-details { width:100%!important; }
</style>
<template>
	<div class="">
		<section class="content">
			<div class="container-fluid">
				<div class="row">
					<section class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3">
						<div class="card">
							<div class="card-body table-responsive table-head">
								<div class="row bor-bot pb-3">
									<div class="col-md-7 pl-0">
										<h1 class="m-0 text-dark pt-2 pb-2">Merchandise Accounting Report</h1>
									</div>
									<div class="col-md-5">
										<div class="text-right pb-3">
											<ol class="breadcrumb float-sm-right">
												<li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
												<li class="breadcrumb-item"><Breadcrumbs/></li>
											</ol>
										</div> 
									</div>
								</div>
								
								<div class="float-right pb-3">
									<b-button class="btn-darkblue btn" :class="{ 'filter-applied': filtersApplied }" v-b-modal.modal-donation-filter><i class="fas fa-sliders-h"></i> Filters</b-button>
									<b-button class="ml-1 btn-primary btn-darkblue btn" @click="refreshList" v-if="filtersApplied"><i class="fas fa-redo"></i> Reset</b-button>
									<download-excel :fetch="fetchTodos" :fields="btn.excel.fields" :before-generate="beforeGenerate" :before-finish="beforeFinish" name="Merchandise Accounting Report.xls" v-if="btn.excel.status == true" class="btn p-0">
										<button class="btn ml-1 btn-primary btn-darkblue btn btn-secondary"><i class="fas fa-file-download"></i> Export</button>
									</download-excel>
									<button disabled class="btn ml-1 btn btn-secondary disabled" v-if="btn.excel.status == false"><i class="fas fa-circle-notch fa-spin"></i> Generating Report</button>
								</div>
								<div class="table-responsive mt-3">
									<table class="table table-sm table-bordered table-hover" id="list_table">
										<thead class="thead-dark">
											<tr><th class="all">Order Date</th>
												<th class="all">Member #</th>
												<th class="all">First Name</th>
												<th class="all">Last Name</th>
												<th class="all">City</th>
												<th class="all">State</th>
												<th class="all">Fulfilled</th>
												<th class="all">Fulfilled Date</th>
												<th class="all">Subtotal</th>
												<th class="all">Shipping Cost</th>
												<th class="all">Total Paid</th>
												<th class="none">&nbsp;</th></tr>
										</thead>
										<tbody></tbody>
									</table>
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
		</section>
		<b-modal id="modal-donation-filter" v-bind:hide-footer="true" ref="modal-donation-filter" size="lg"  title="Search Merchandise Accounting">
			<form ref="form" method="post" @submit.prevent="advancedSearch()">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group">
							<label for="order_start_date" class="form-label">Order From Date</label>
							<div class="input-group birthdate">
								<div class="birthdaypicker">
									<date-pick
										v-model="searchForm.order_start_date"
										:format="'MM/DD/YYYY'"
										:displayFormat="'MM/DD/YYYY'"
										v-mask="'##/##/####'"
                    :parseDate="parseDatePick"
									></date-pick>
								</div>
							</div>
							<input id="order_start_date" type="hidden" class="form-control" :value="order_start_date_1">
							<div class="text-sm text-danger">{{ errors.order_start_date }}</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group">
							<label for="order_end_date" class="form-label">Order To Date</label>
							<div class="input-group birthdate">
								<div class="birthdaypicker">
									<date-pick
										v-model="searchForm.order_end_date"
										:format="'MM/DD/YYYY'"
										:displayFormat="'MM/DD/YYYY'"
										v-mask="'##/##/####'"
                    :parseDate="parseDatePick"
									></date-pick>
								</div>
							</div>
							<input id="order_end_date" type="hidden" class="form-control" :value="order_end_date_1">
							<div class="text-sm text-danger">{{ errors.order_end_date }}</div>
						</div>
					</div>
				</div>
				<p class="text-center"><button type="submit" class="btn btn-primary">Search</button></p>
			</form>
		</b-modal>
	</div>
</template>

<script>
import JsonExcel from "vue-json-excel";
import moment from 'moment';
import $ from "jquery";
import "datatables.net-responsive-dt/js/responsive.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";

import DatePick from "vue-date-pick";
import axios from 'axios';

const validateName = name => {
if (name.length || name != "") {
	return { valid: true, error: null };
}
return { valid: false, error: null };
};

export default {
	name: "DonationReport",
	data(){
		return {
			membershipData: [],
			table: {},
			errors: {},
			disciplines_list: [],
			filtersApplied: false,
			valid: false,
			searchForm: {
				order_start_date: '',
				order_end_date: '',
				sort_by: '',
				order_by: ''
			},
			btn:{excel:{status:true, fields: {
				'Order ID' : "id",
				'Order Date':	"orderdate",
				'Member #': "member_id",
				'First Name' : "firstname",
				'Last Name' : "lastname",
				'city' : "city",
				'state' : "state",
				'Fulfilled' : "fulfilled",
				'Fulfilled Date' : "fulfilled_date",
				'Item Description': 'product',
				'Style' : 'style',
				'Size' : 'size', 
				'Price' : 'price',
				'Quantity' : 'quantity',
				' ': 'finaltotal',
				'Total': 'total'
				}}}
		};
	},
	methods: {
		loadDonationData(){
		var self = this;
		this.table = $("#list_table").DataTable({
			sDom: "lrtip",
			processing: true,
			serverSide: true,
			pageLength: 10,
			"aLengthMenu": [[10, 50, 100, 250, 500, -1],
							[10, 50, 100, 250, 500, "All"]],
			bFilter: true,
			rowReorder: {
				selector: 'td:nth-child(0)'
			},
			"aaSorting": [],
			"order": [[ 0, 'desc' ], [ 3, 'asc' ], [ 2, 'asc' ]],
			responsive: true,
			ajax: {
				url: process.env.VUE_APP_URL + "api/reports/merchandise-accounting",
				type: 'post',
				data: function (d) {
					d.order_start_date = $("#order_start_date").val() || self.searchForm.order_start_date;
					d.order_end_date = $("#order_end_date").val() || self.searchForm.order_end_date;
				}
			},
			columns: [
				{ data: 'created_at', name: "created_at", width: '5%', orderable: true, searchable: false, render: (data, type, full, meta) => {
					return full.created_at; } },
				{ data: null, name: "", width: '5%', orderable: false, render: (data, type, full, meta) => {
					return full.registration.member.confirmation_code; } },
				{ data: 'registration.personal_details.first_name', name: 'Registration.personal_details.first_name', width: '5%', orderable: true, searchable: false},
				{ data: "registration.personal_details.last_name", name: "Registration.personal_details.last_name", width: '5%', orderable: true, searchable: false },
				{ data: null, name: "", width: '5%', orderable: false, class:"text-center", render: (data, type, full, meta) => {
					return full.registration.contact_details.city; } },
				{ data: null, name: "", width: '5%', orderable: false, class:"text-center", render: (data, type, full, meta) => {
					return full.registration.contact_details.state_code; } },
				{ data: null, name: "", width: '5%', orderable: false, class:"text-center", render: (data, type, full, meta) => {
					return (full.fulfilled != undefined && full.fulfilled == 1 ? 'Yes' : 'No'); } },
				{ data: null, name: "", width: '5%', orderable: false, class:"text-center", render: (data, type, full, meta) => {
					return (full.fulfilled_date != undefined && full.fulfilled_date != '' ? this.formatDate(full.fulfilled_date) : 'N/A'); } },
				{ data: null, name: "", width: '5%', orderable: false, class:"text-right", render: (data, type, full, meta) => {
					return full.sub_total; } },
				{ data: null, name: "", width: '5%', orderable: false, class:"text-right", render: (data, type, full, meta) => {
					return full.shipping_cost; } },
				{ data: null, name: "", width: '5%', orderable: false, class:"text-right", render: (data, type, full, meta) => {
					return full.total; } },
				{ data: null, name: "", width: '75%', orderable: false, render: (data, type, full, meta) => {
					var ff = '<table class="table table-sm">'+
					'<thead><tr><th class="px-1" width="55%">Item Description</th><th class="px-1" width="5%">Style</th><th class="px-1" width="5%">Size</th><th class="px-1" width="10%">Unit Price</th><th class="px-1" width="10%">Qty</th><th class="px-1" width="10%">Total</th></tr></thead><tbody>';
					if(full.merchandise_carts.length > 0) {
						$.each(full.merchandise_carts, function(i, v) {
							let vari = { 'color':'', 'size': '' };
							$.each(v.cart_variants, function(vi, vv) {
								if(vv.merchandise_product_variant.name == 'Size') { vari.size = vv.name; }
								if(vv.merchandise_product_variant.name == 'Color') { vari.color = vv.name; }
							});
							ff += '<tr><td>'+v.name+'</td><td>'+vari.color+'</td><td>'+vari.size+'</td><td class="text-right">'+v.price+'</td><td class="text-right">'+v.quantity+'</td><td class="text-right">'+v.total+'</td></tr>';
						});
					ff += '</tbody><tfoot class="font-weight-bold"><tr><td colspan="5">Sub Total</td><td class="text-right">'+full.sub_total+'</td></tr></tfoot>';
					} else { ff += '<tr><td colspan="12" class="text-center">No products purchased</td></tr>'; }
					ff += '</table>';
					return ff;
				} },
			]
		});
		},
		advancedSearch() {
			if(!this.validate()) {
				return;
			}
			this.table.draw();
			this.filtersApplied = true;
			this.$refs['modal-donation-filter'].hide();
		},
		validate(){
			this.errors = {}
			this.valid =  true;
			const validTStartDate = validateName(this.searchForm.order_start_date);
			this.errors.order_start_date = validTStartDate.error;

			const validTEndDate = validateName(this.searchForm.order_end_date);
			this.errors.order_end_date = validTEndDate.error;
			
			let dateValid = false;
			if(validTStartDate.valid && !this.validateDate(this.searchForm.order_start_date)){
				dateValid = true;
				this.errors.order_start_date = "Invalid Date";
			}
			if(validTEndDate.valid && !this.validateDate(this.searchForm.order_end_date)){
				dateValid = true;
				this.errors.order_end_date = "Invalid Date";
			}

			if(dateValid == false){
				if((validTStartDate.valid && validTEndDate.valid) && (moment(this.searchForm.order_end_date).isBefore(this.searchForm.order_start_date))) {
					this.errors.order_end_date = 'End date must be greater than Start date!';
					return this.valid =  false;
				} else {
					this.valid = true;
				}
			} else {
				this.valid = false;
			}

			return this.valid;
		},
		refreshList(event){
			this.searchForm = {
				order_start_date: '',
				order_end_date: '',
				sort_by: '',
				order_by: ''
			};
			// this.table.clear().draw();
			this.table.ajax.reload();
			this.filtersApplied = false;
		},
		formatDate(value) {
			var date=  moment(value, "YYYY-MM-DD hh:mm:ss");
			if(date.isValid()){
				return date.format('MM/DD/YYYY');
			} else {
				return 'N/A';
			}
		},
		async fetchTodos(){

			let filterdata = { order_start_date: $("#order_start_date").val() || this.searchForm.order_start_date,
						order_end_date: $("#order_end_date").val() || this.searchForm.order_end_date };
			let param = this.table.ajax.params();

			param.start = 0;
			param.length = -1;

			const response = await axios.post(process.env.VUE_APP_URL + "api/reports/merchandise-accounting/export/excel",  param);

			let mar = [];
			$.each(response.data.data, function(i, v) {
				if(v.merchandise_carts.length > 0) {
					$.each(v.merchandise_carts, function(mci, mcv) {
						let vari = { 'color':'', 'size': '' };
						$.each(mcv.cart_variants, function(vi, vv) {
							if(vv.merchandise_product_variant.name == 'Size') { vari.size = vv.name; }
							if(vv.merchandise_product_variant.name == 'Color') { vari.color = vv.name; }
						});
						mar.push({"id": (mci == 0 ? v.id : ''),
								"orderdate": (mci == 0 ? (v.created_at != '' && v.created_at != null ? v.created_at : '') : ''),
								"member_id": (mci == 0 ? v.registration.member.confirmation_code : ''),
								"firstname": (mci == 0 ? v.registration.member.personal_details.first_name : ''),
								"lastname": (mci == 0 ? v.registration.member.personal_details.last_name : ''),
								"city": (mci == 0 ? v.registration.contact_details.city : ''),
								"state": (mci == 0 ? v.registration.contact_details.state_code : ''),
								"fulfilled": (mci == 0 ? (v.fulfilled != undefined && v.fulfilled == 1 ? 'Yes' : 'No') : ''),
								"fulfilled_date":  (mci == 0 ? (v.fulfilled_date != undefined && v.fulfilled_date != '' ? moment(v.fulfilled_date, "YYYY-MM-DD HH:mm:ss").format('MM/DD/YYYY') : 'N/A') : ''),
								'product': mcv.name,
								'style': vari.color,
								'size': vari.size,
								'price': mcv.price,
								'quantity': mcv.quantity,
								'finaltotal': '',
								'total': mcv.total });
					});
				} else {
					mar.push({"id": v.id,
							"orderdate": (v.created_at != '' && v.created_at != null ? v.created_at : ''),
							"member_id": v.registration.member.confirmation_code,
							"firstname": v.registration.member.personal_details.first_name,
							"lastname": v.registration.member.personal_details.last_name,
							"city": v.registration.contact_details.city,
							"state": v.registration.contact_details.state_code,
							"fulfilled": (v.fulfilled != undefined && v.fulfilled == 1 ? 'Yes' : 'No'),
							"fulfilled_date":  (v.fulfilled_date != undefined && v.fulfilled_date != '' ? moment(v.fulfilled_date, "YYYY-MM-DD").format('MM/DD/YYYY') : 'N/A'),
							'product':'',
							'style':'',
							'size':'',
							'price': '',
							'quantity': '',
							'finaltotal': '',
							'total': '' });
				}
				mar.push({"id": '', "orderdate": '', "member_id": '', "firstname": '', "lastname": '', "city": '', "state": '', 'fulfilled': '', 'fulfilled_date': '', 'product':'', 'style':'', 'size':'', 'price': '', 'quantity': '', 'finaltotal': 'Subtotal', 'total': v.sub_total });
				mar.push({"id": '', "orderdate": '', "member_id": '', "firstname": '', "lastname": '', "city": '', "state": '', 'fulfilled': '', 'fulfilled_date': '', 'product':'', 'style':'', 'size':'', 'price': '', 'quantity': '', 'finaltotal': 'Shipping Cost', 'total': v.shipping_cost});
				mar.push({"id": '', "orderdate": '', "member_id": '', "firstname": '', "lastname": '', "city": '', "state": '', 'fulfilled': '', 'fulfilled_date': '', 'product':'', 'style':'', 'size':'', 'price': '', 'quantity': '', 'finaltotal': 'Total Paid', 'total': v.total});
				mar.push({"id": '', "orderdate": '', "member_id": '', "firstname": '', "lastname": '', "city": '', "state": '', 'fulfilled': '', 'fulfilled_date': '', 'product':'', 'style':'', 'size':'', 'price': '', 'quantity': '', 'finaltotal': '', 'total': ''});
			});
			return mar;
		},
		beforeGenerate(){
			this.btn.excel.status=false;
		},
		beforeFinish(){
			this.btn.excel.status=true;
		}
	},
	mounted() {
		this.loadDonationData();
		this.getMembership();

		const retry = setInterval(() => {
		this.disciplines_list = this.getSiteProps('memberRegistration.individual.fields.donation.disciplines.options');
		if(this.disciplines_list) clearInterval(retry);
		}, 200);
	}
}
</script>